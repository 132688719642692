import * as firebase from 'firebase/app';
import 'firebase/database';

const firebaseConfig = {
  apiKey: 'AIzaSyAIN4WToj3TWksJPU775c0mazTvvEb2uT0',
  authDomain: 'frogo-ad-website.firebaseapp.com',
  databaseURL: 'https://frogo-ad-website.firebaseio.com'
};

const firebaseService = {
  requestOffer: (data: { name: string; email: string }) => {
    const firebaseApp = firebase.initializeApp(firebaseConfig);
    const firebaseDatabase = firebase.database();

    return firebaseDatabase
      .ref('offer-requests')
      .push({
        name: data.name.trim(),
        email: data.email.trim(),
        time: firebase.database.ServerValue.TIMESTAMP
      })
      .then(() => {
        firebaseDatabase.goOffline();
        firebaseApp.delete();
      });
  },

  requestAnalysis: (data: {
    name: string;
    email: string;
    googleAdsId: string;
    phoneNumber: string;
    wantsMoreCampaigns: boolean;
    privacyPolicyAccepted: boolean;
  }) => {
    const firebaseApp = firebase.initializeApp(firebaseConfig);
    const firebaseDatabase = firebase.database();

    return firebaseDatabase
      .ref('analysis-requests')
      .push({
        name: data.name.trim(),
        email: data.email.trim(),
        googleAdsId: data.googleAdsId.trim(),
        phoneNumber: data.phoneNumber.trim(),
        wantsMoreCampaigns: data.wantsMoreCampaigns,
        privacyPolicyAccepted: data.privacyPolicyAccepted,
        time: firebase.database.ServerValue.TIMESTAMP
      })
      .then(() => {
        firebaseDatabase.goOffline();
        firebaseApp.delete();
      });
  },

  requestAdMechanic: (data: {
    name: string;
    email: string;
    phoneNumber: string;
    privacyPolicyAccepted: boolean;
  }) => {
    const firebaseApp = firebase.initializeApp(firebaseConfig);
    const firebaseDatabase = firebase.database();

    return firebaseDatabase
      .ref('ad-mechanic-requests')
      .push({
        name: data.name.trim(),
        email: data.email.trim(),
        phoneNumber: data.phoneNumber.trim(),
        privacyPolicyAccepted: data.privacyPolicyAccepted,
        time: firebase.database.ServerValue.TIMESTAMP
      })
      .then(() => {
        firebaseDatabase.goOffline();
        firebaseApp.delete();
      });
  },

  requestContact: (data: {
    name: string;
    email: string;
    phoneNumber: string;
    message: string;
    privacyPolicyAccepted: boolean;
  }) => {
    const firebaseApp = firebase.initializeApp(firebaseConfig);
    const firebaseDatabase = firebase.database();

    return firebaseDatabase
      .ref('contact-requests')
      .push({
        name: data.name.trim(),
        email: data.email.trim(),
        phoneNumber: data.phoneNumber.trim(),
        message: data.message.trim(),
        privacyPolicyAccepted: data.privacyPolicyAccepted,
        time: firebase.database.ServerValue.TIMESTAMP
      })
      .then(() => {
        firebaseDatabase.goOffline();
        firebaseApp.delete();
      });
  }
};

export default firebaseService;
