import * as React from 'react';
import Helmet from 'react-helmet';

import Layout from '../components/Layout';
import SwappingNav from '../components/SwappingNav';
import TopNavbar from '../components/TopNavBar';
import IntroSectionContact from '../components/Contact/IntroSectionContact';
import AdressSectionContact from '../components/Contact/AdressSectionContact';
import FormSectionContact from '../components/Contact/FormSectionContact';

import Footer from '../components/Footer';

export default () => {
  return (
    <Layout>
      <Helmet>
        <title>Kontakt</title>
      </Helmet>
      <SwappingNav
        scrollInHeight={15}
        initialNavbar={<TopNavbar transparent={true} />}
        swappedNavbar={<TopNavbar />}
      />
      <main>
        <IntroSectionContact />
        <FormSectionContact />
        <AdressSectionContact />
        <Footer />
      </main>
    </Layout>
  );
};
