import * as React from 'react';
import { Container, Row, Col } from 'reactstrap';

import './IntroSectionContact.scss';
import Slant from '../Slant';

const IntroSectionContact = () => {
  return (
    <section className="IntroSectionContact text-white">
      <Container>
        <Row className="pt-2 px-sm-5">
          <Col xs={12} md={7}>
            <h6 className="mb-0">Kontakt</h6>
            <h2 className="mb-0">Interesse an FROGO&nbsp;AD?</h2>
            <p className="lead IntroSectionContact-text">
              Nehmen Sie jetzt Kontakt auf – wir melden uns in Kürze bei Ihnen
            </p>
          </Col>
        </Row>
      </Container>
      <Slant style={{ color: '#fff' }} />
    </section>
  );
};

export default IntroSectionContact;
