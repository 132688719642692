import * as React from 'react';

import { Link } from 'gatsby';

import { Container, Button, Row, Col, Label } from 'reactstrap';
import {
  AvForm as Form,
  AvGroup as FormGroup,
  AvInput as Input,
  AvField
} from 'availity-reactstrap-validation';

import firebaseService from '../../services/firebaseService';

import './FormSectionContact.scss';

enum Title {
  MRS = 'Frau',
  MR = 'Herr'
}

interface ContactFormState {
  name: string;
  email: string;
  phoneNumber: string;
  message: string;
  privacyPolicyAccepted: boolean;
  sending: boolean;
  success: boolean;
}

class ContactForm extends React.Component<{}, ContactFormState> {
  form: any;

  constructor(props: {}) {
    super(props);
    this.state = {
      name: '',
      email: '',
      phoneNumber: '',
      message: '',
      privacyPolicyAccepted: false,
      sending: false,
      success: false
    };
  }

  handleChange = <T extends keyof ContactFormState>(
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const target = event.currentTarget;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    const newState = {
      [name]: value
    };
    this.setState(newState as { [P in T]: ContactFormState[P] });
  };

  handleValidSubmit = () => {
    this.setState({ sending: true });

    firebaseService
      .requestContact({
        name: this.state.name,
        email: this.state.email,
        phoneNumber: this.state.phoneNumber,
        message: this.state.message,
        privacyPolicyAccepted: this.state.privacyPolicyAccepted
      })
      .then(() => {
        this.setState({
          sending: false,
          success: true
        });
        setTimeout(() => {
          this.setState({
            name: '',
            email: '',
            phoneNumber: '',
            message: '',
            privacyPolicyAccepted: false,
            success: false
          });
          this.form && this.form.reset();
        }, 5000);
      });
  };

  render() {
    const state = this.state;

    let buttonContent;
    if (state.sending) {
      buttonContent = 'Einen Moment bitte ...';
    } else if (state.success) {
      buttonContent = 'Danke und bis bald!';
    } else {
      buttonContent = 'Nachricht senden';
    }

    return (
      <Form
        onValidSubmit={this.handleValidSubmit}
        ref={(component: any) => (this.form = component)}
        className="text-left"
      >
        <FormGroup>
          <Label for="name" sm={12}>
            Name*
          </Label>
          <Input
            type="text"
            name="name"
            id="name"
            className="FormSectionContact-input"
            disabled={this.state.sending || this.state.success}
            value={this.state.name}
            onChange={this.handleChange}
            required={true}
          />
        </FormGroup>
        <FormGroup>
          <Label for="Email" sm={12}>
            E-Mail*
          </Label>
          <Input
            type="email"
            name="email"
            id="email"
            className="FormSectionContact-input"
            disabled={this.state.sending || this.state.success}
            value={this.state.email}
            onChange={this.handleChange}
            required={true}
          />
        </FormGroup>
        <FormGroup>
          <Label for="phoneNumber" sm={12}>
            Telefonnummer
          </Label>
          <Input
            type="text"
            name="phoneNumber"
            id="phoneNumber"
            className="FormSectionContact-input"
            disabled={this.state.sending || this.state.success}
            value={this.state.phoneNumber}
            onChange={this.handleChange}
          />
        </FormGroup>
        <FormGroup>
          <Label for="message" sm={12}>
            Ihre Nachricht*
          </Label>
          <AvField
            id="message"
            name="message"
            type="textarea"
            className="form-control FormSectionContact-input"
            value={state.message}
            onChange={this.handleChange}
            required={true}
            errorMessage={'Dieses Feld ist ein Pflichtfeld'}
          />
        </FormGroup>
        <FormGroup check={true}>
          <Label check={true} className="text-left">
            <Input
              type="checkbox"
              name="privacyPolicyAccepted"
              id="privacyPolicyAccepted"
              disabled={this.state.sending || this.state.success}
              checked={this.state.privacyPolicyAccepted}
              onChange={this.handleChange}
              required={true}
            />
            Ich habe die{' '}
            <Link
              className="FormSectionContact-link"
              to="/datenschutz"
              target="_blank"
            >
              Datenschutzerklärung
            </Link>{' '}
            zur Kenntnis genommen und stimme zu, dass meine Daten zur
            Beantwortung meiner Anfrage elektronisch erhoben und verarbeitet
            werden.*
          </Label>
        </FormGroup>
        <Button
          color={state.success ? 'success' : 'primary'}
          size="md"
          className="text-center w-100 FormSectionContact-button mt-3"
          disabled={state.sending || state.success}
        >
          {buttonContent}
        </Button>
      </Form>
    );
  }
}

const FormSectionContact = () => {
  return (
    <section className="FormSectionContact">
      <Container>
        <Row className="pt-2 px-sm-5">
          <Col xs={12} lg={8}>
            <div className="pt-2 lead FormSectionContact-content">
              <ContactForm />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default FormSectionContact;
