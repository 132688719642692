import * as React from 'react';
import { Container, Row, Col } from 'reactstrap';

import './AdressSectionContact.scss';

const AdressSectionContact = () => {
  return (
    <section className="AdressSectionContact">
      <Container>
        <Row className="mt-4 pt-2 px-sm-5">
          <Col xs={12}>
            <div className="lead text-left text-md-left AdressSectionContact-text">
              <p className="AdressSectionContact-headline">Kontaktdaten</p>
              <p>
                <b>FROGO AD</b> UG (haftungsbeschränkt) i. L.
                <br />
                c/o Freie Universität Berlin
                <br />
                Malteser Straße 74-100
                <br />
                12249 Berlin
              </p>
              <p>
                +49 (0)30 / 403646 85
                <br />
                <b>contact@frogo-ad.com</b>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AdressSectionContact;
